.ReactVirtualized__List {
  flex: 2;
  padding-right: 8px;
  scroll-margin-left: 4px;
  width: unset !important;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #e5e5e5;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #595959;
    border-radius: 4px;
    outline: "1px solid slategrey";
    background-clip: content-box;
  }
}

.ReactVirtualized__Grid__innerScrollContainer {
  max-width: unset !important;
}
