.not-empty-patient-search {
  position: absolute;
  top: 25%;
  transform: translate(40%, 50%);
  z-index: 1001;
}

.empty-patient-search {
  position: relative;
  z-index: 1001;
}
